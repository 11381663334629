<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('TelemarketingQuestionsCreate')"
    @edit="(id) => onEdit('TelemarketingQuestionsEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TelemarketingQuestions',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TelemarketingQuestions',
      tableCaption: 'Вопросы по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Вопросы по телемаркетингу',
        },
      ],
      tableHeaders: [
        { text: 'Скрипт', alias: 'script', order: 'script' },
        { text: 'Вопрос', alias: 'question', order: 'question' },
        {
          text: 'Номер вопроса (для ссылок перехода к следующему вопросу)',
          alias: 'number',
          order: 'number',
        },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
